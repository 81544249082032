<template>
  <Transition name="popup" @enter="onEnter" @leave="onLeave">
    <div v-if="shown" class="mobile-popup-container">
      <div class="mobile-popup">
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script setup>
const props = defineProps({
  shown: Boolean
})

const keyframes = [
  { transform: 'translateX(0)' },
  { transform: 'translateX(-15%)' }
]
const options = { duration: 400, easing: 'ease' }
const onEnter = () => document.getElementById('__nuxt')?.animate(keyframes, options)
const onLeave = () => document.getElementById('__nuxt')?.animate(keyframes.slice().reverse(), options)

useModal(props)
</script>

<style scoped lang="scss">
.mobile-popup-container {
  position: fixed;
  inset: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, .5);

  .mobile-popup {
    background: var(--color-bg);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 56px;
  }
}

.popup-enter-active, .popup-leave-active {
  &, & .mobile-popup {
    transition: .4s ease;
  }
}
.popup-enter-from, .popup-leave-to {
  background: rgba(0, 0, 0, 0);
  .mobile-popup {
    transform: translateX(100%);
  }
}
</style>
